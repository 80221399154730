import request from "@/utils/request";

// 我发布的项目列表
export function myPublishedProject(data) {
    return request({
        url: '/myCenter/myPublishedProject',
        method: 'get',
        params: data
    })
}


// 我发布的项目详情
export function myVipProjectDetails(data) {
    return request({
        url: '/myCenter/myVipProjectDetails',
        method: 'post',
        params: data
    })
}

//3、我发布的项目-详情页-4、感兴趣 、会员推荐、 浏览过A
export function dataTypePageTable(data) {
    return request({
        url: '/myCenter/dataTypePageTable',
        method: 'get',
        params: data
    })
}

//我发布的项目-详情页-5、多人邀约记录A
export function invitePeopleRecord(data) {
    return request({
        url: '/myCenter/invitePeopleRecord',
        method: 'get',
        params: data
    })
}

//我发布的项目-详情页 多人邀请页面详情
export function skipToMultiInvite(data) {
    return request({
        url: '/myCenter/skipToMultiInvite',
        method: 'get',
        params: data
    })
}

//我发布的项目-删除项目
export function delProjectById(data) {
    return request({
        url: '/project/delProjectById',
        method: 'get',
        params: data
    })
}

//编辑项目
//编辑找人项目
export function editProjectForSomeone(data) {
    return request({
        url: '/project/insertProjectForSomeone',
        method: 'post',
        data
    })
}

//编辑找钱项目
export function editProjectForFinancing(data) {
    return request({
        url: '/project/insertProjectForFinancing',
        method: 'post',
        data
    })
}

//编辑找智慧项目
export function editProjectForWisdom(data) {
    return request({
        url: '/project/insertProjectForWisdom',
        method: 'post',
        data
    })
}

//项目详情
export function selectMainProjectBaseInfoById(data) {
    return request({
        url: '/project/selectMainProjectBaseInfoById',
        method: 'post',
        params: data
    })
}

//编辑三分钟访谈项目

//项目详情
export function selectThreeProjectById(data) {
    return request({
        url: '/interview/selectThreeProjectById',
        method: 'post',
        data
    })
}

//编辑

export function editInterviewForPerson(data) {
    return request({
        url: '/interview/publishInterviewForPerson',
        method: 'post',
        data
    })
}
