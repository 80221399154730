<template>
    <div class="index-container">
        <BreadcrumbNav :page-name="$t('myProject.myProject')"/>
        <div class="main">
            <!--TAB按钮：我的项目、我发布的项目、我参与的项目、我的访谈、我的人脉-->
            <div class="btn-tab">
                <CustomButton :active="tabs[0]===this.$route.path" @click.native="checked(0)" class="btn-tab-item fl">
                    {{ $t("myProject.myPublishedProject") }}
                </CustomButton>
                <CustomButton :active="tabs[1]===this.$route.path" @click.native="checked(1)" class="btn-tab-item fl">
                    {{ $t("myProject.myJoinProject") }}
                </CustomButton>
                <CustomButton :active="tabs[2]===this.$route.path" @click.native="checked(2)" class="btn-tab-item fl">
                    {{ $t("myProject.myInterview") }}
                </CustomButton>
                <CustomButton :active="tabs[3]===this.$route.path" @click.native="checked(3)" class="btn-tab-item fl">
                    {{ $t("myProject.myContacts") }}
                </CustomButton>
            </div>
            <!--四个TabView-->
            <router-view></router-view>
        </div>
        <!--弹窗-->
        <!-- <div class="pop" v-if="isPopRefuse">
          <div class="pop_refuse">
            <div class="pop_close">
              <img src="../../assets/images/icon_closed@2x.png" width="16px" class="cur" @click="isPopRefuse = false" alt="">
            </div>
            <div class="pop_title">{{$t("vipRecommend.reasonsForRefuse")}}</div>
            <div class="pop_title_item">
              <span>*</span>
              {{$t("publish.phone")}}
            </div>
            <div class="pop_input_number">
              <span>+86</span>
              <input type="text" :placeholder="$t('publish.input')">
            </div>
            <div class="pop_title_item">
              <span>*</span>
              {{$t("publish.mailbox")}}
            </div>
            <div class="pop_input">
              <input type="text" :placeholder="$t('publish.input')">
            </div>
            <div class="pop_title_item">
              WhatsApp
            </div>
            <div class="pop_input">
              <input type="text" :placeholder="$t('publish.input')">
            </div>
            <div class="pop_textarea">
              <textarea name="" id="" cols="30" :placeholder="$t('bulletFrame.cancelReason')" rows="10"></textarea>
            </div>
            <div class="pop_submit cur auto">
              {{$t("home.submit")}}
            </div>
          </div>
        </div> -->
    </div>
</template>

<script>

    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/CustomButton";
    import Table1 from "@/components/my_project/Table1";
    import Table2 from "@/components/my_project/Table2";
    import UlList1 from "@/components/my_project/my_published_projects/UlList1";
    import UlList2 from "@/components/my_project/UlList2";

    export default {
        name: "index",
        data() {
            return {
                tabs: ['/myProject/publishTab', '/myProject/participateTab', '/myProject/interviewTab', '/myProject/relationshipTab'],
                isPopRefuse: false,
            };
        },
        components: {
            BreadcrumbNav,
            CustomButton,
            Table1,
            Table2,
            UlList1,
            UlList2,
        },
        props: {},
        mounted() {
            console.log("路由：" + this.$route.path)
        },
        methods: {
            checked: function (index) {
                switch (index) {
                    case 0:
                        console.log(this.$route.path)
                        this.$router.push('publishTab');
                        break;
                    case 1:
                        console.log(this.$route.path)
                        this.$router.push('participateTab');
                        break;
                    case 2:
                        this.$router.push('interviewTab');
                        break;
                    case 3:
                        this.$router.push('relationshipTab');
                        break;
                }
            },
            //拒绝弹窗
            isRefuse(data) {
                console.log(data)
                this.isPopRefuse = data
            }

        }
    }
</script>

<style scoped>
    .index-container {
        min-height: calc(100vh - 182px);
        background-color: #FFFFFF;
    }

    .main {
        width: 1200px;
        margin: 0 auto;
        padding-top: 31px;
        padding-bottom: 60px;
    }

    .btn-tab {
        overflow: hidden;
    }

    .btn-tab-item {
        margin-right: 20px;
    }

    .way-tab {
        overflow: hidden;
        background: #FAFAFA;
        border-top: 1px solid #E5E5E5;
        margin-top: 39px;
    }

    .wt-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 47px;
        color: #949494;
        margin: 0 16px;
        display: inline-block;
    }

    .wt-area {
        overflow: hidden;
    }

    .wt-btn {
        box-sizing: border-box;
        padding: 0 16px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 32px;
        color: #000000;
        text-align: center;

        margin-right: 16px;
        margin-top: 8px;
        float: left;
    }

    .active {
        border: 1px solid #707070;
    }


    .pop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.22);
    }

    .pop_refuse {
        width: 600px;
        height: 759px;
        background: #FFFFFF;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 22px;
        margin: 2% auto;
    }

    .pop_close {
        text-align: right;
    }

    .pop_title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        text-align: center;
        margin-bottom: 33px;
    }

    .pop_title_item {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .pop_title_item span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
    }

    .pop_input_number, .pop_input {
        width: 536px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        padding: 0 26px;
        box-sizing: border-box;
        line-height: 50px;
        margin-top: 22px;
        margin-bottom: 31px;
    }

    .pop_input_number span {
        padding-right: 53px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .pop_input_number input {
        height: 22px;
        border-left: 1px solid #d6d6d6;
        background: #FAFAFA;
        padding-left: 45px;
        width: 300px;
    }

    .pop_input input {
        background: #FAFAFA;
        width: 300px;
    }

    .pop_textarea textarea {
        width: 536px;
        height: 149px;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 17px 24px;
        box-sizing: border-box;
    }

    .pop_submit {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        line-height: 50px;
        text-align: center;
        margin-top: 33px;
        transition: .3s all;
    }

    .pop_submit:hover {
        background: #000000;
        color: #FFFFFF;
    }
</style>
