<template>
    <div>
        <table>
            <thead>
            <tr>
                <!-- 项目基本信息 -->
                <td>{{ $t('myProject.basicProjectInfo') }}</td>
                <!-- 被邀约人 -->
                <td>{{ $t('myProject.invitee') }}</td>
                <!-- 访谈时间 -->
                <td>{{ $t('myProject.interviewTime') }}</td>
                <!-- <td>操作</td> -->
                <!-- 进度 -->
                <td>{{ $t('myProject.speedOfProgress') }}</td>
                <!-- VIP 私密推荐人脉 -->
                <td>{{ $t('myProject.vipPrivateRecommendedContact') }}</td>
                <!-- 价格 -->
                <td>{{ $t('myProject.price') }}</td>
            </tr>
            </thead>
            <tbody>
            <tr :key="index" @click="path_tointerview(item,$event)" v-for="(item, index) in list">
                <td>
                    <div class="project-title over3">{{ item.project_name }}</div>
                    <div class="project-intro over2">{{ item.project_intro }}</div>
                    <div class="people-info" v-if="item.is_hidden_issuer == 1 || item.belong == 1">
                        <div class="head-icon fl">
                            <img :src="item.initiator_avatar" alt="" style="width: 100%; height: 100%"/>
                        </div>
                        <div class="people-info-r fl">
                            <div class="people-name over">{{ item.initiator_name }}</div>
                            <div class="people-intro over">
                                {{ item.company_name }} · {{ item.position }}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="invited-people">
                        <img :src="item.invitee_avatar" alt="" class="invited-people-icon"/>
                        <span class="invited-people-text over2">{{ item.invitee_name }}</span>
                    </div>
                </td>
                <td>
                    <div class="interview-time" v-if="item.invitation_time">{{ item.invitation_time }}</div>
                    <div class="no-op" style="text-align: center" v-else>-</div>
                </td>
                <td>
                    <!--<h1 class="speed-progress">已参加此项目</h1>-->
                    <h2 class="speed-progress-tip">
                        <ProgressBar :id="item.interview_id" :interview-type="item.data_type==3?2:3"
                                     v-if="item.progress_name">
                            {{ item.progress_name }}
                        </ProgressBar>
                        <div class="no-op" v-else>-</div>
                    </h2>
                </td>
                <td>
                    <div class="icon-value" v-if="item.belong == 1 && item.introduceList">
                        <div class="icon-stack">
                            <img :src="value.avatar" alt="" v-bind:key="index"
                                 v-for="(value,index) in item.introduceList"/>
                            <img alt="" src="@/assets/images/img_more@2x.png" v-if="item.introduceList.length >2"/>
                        </div>
                        <div class="icon-value-text" v-if="item.introduceList.length !== 0">
                            {{ item.introduceList.length + $t("myProject.personFreeCommunication") }}
                        </div>
                    </div>
                </td>
                <td>
                    <div class="price">{{ item.exchange_cost }}</div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import ProgressBar from "@/components/progress_bar/ProgressBar";

    export default {
        name: "TBodyList",
        data() {
            return {
                datalist: [1, 1, 1, 1, 1],
            };
        },
        components: {ProgressBar},
        props: {
            list: {
                default: [],
            },
        },
        mounted() {
        },
        methods: {
            haha() {
                console.log("hahah")
            },

            path_tointerview(valKey, event) {
                if (valKey.data_type == 1) {
                    this.$router.push({
                        path: "/myInterview/publishedInterview/" + valKey.project_id,
                        query: {
                            id: valKey.project_id,
                            pd_num: valKey.data_type,
                            join_id: valKey.join_id,
                        },
                    });
                } else if (valKey.data_type == 3) {
                    //平台推荐的项目
                    this.$router.push({
                        path: "/myInterview/receiveInterviewVip/" + valKey.project_id,
                        query: {
                            id: valKey.project_id,
                            pd_num: valKey.data_type,
                            join_id: valKey.join_id,
                        },
                    });
                } else if (valKey.data_type == 2) {
                    this.$router.push({
                        path: "/myInterview/receiveInterview/" + valKey.project_id,
                        query: {
                            id: valKey.project_id,
                            pd_num: valKey.data_type,
                            join_id: valKey.join_id,
                        },
                    });
                } else if (valKey.data_type == 4) {
                    this.$router.push({
                        path: "/myInterview/receiveMulti/" + valKey.project_id,
                        query: {
                            id: valKey.project_id,
                            pd_num: valKey.data_type,
                            join_id: valKey.interview_id,
                        },
                    });
                }
            },
            refuse() {
                this.$emit("isRefuse", true);
            },
        },
    };
</script>

<style scoped>
    table {
        width: 100%;
        margin-top: 26px;
        /*table-layout: fixed*/
        margin-bottom: 50px;
    }

    table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    table > thead > tr > td {
        vertical-align: middle;
        text-align: center;
    }

    table > thead > tr > td:first-child {
        width: 183px;
    }

    table > thead > tr > td:nth-child(2) {
        width: 88px;
    }

    table > thead > tr > td:nth-child(3) {
        width: 185px;
    }

    table > thead > tr > td:nth-child(4) {
        width: 173px;
    }

    table > thead > tr > td:nth-child(5) {
        width: 152px;
    }

    table > thead > tr > td:nth-child(6) {
        width: 214px;
    }

    tbody > tr {
        height: 174px !important;
        box-sizing: border-box;
    }

    tbody > tr > td {
        padding-top: 52px;
        height: 174px !important;
        box-sizing: border-box;
        vertical-align: top;
    }

    .project-title {
        width: 145px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 21px;
        color: #000000;
    }

    .project-intro {
        width: 145px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        margin-top: 13px;
    }

    .people-info {
        width: 183px;
        height: 49px;
        margin-top: 20px;
        overflow: hidden;
    }

    .people-info .head-icon {
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        overflow: hidden;
    }

    .people-info .people-info-r {
        margin-left: 12px;
        padding-top: 4px;
        height: 100%;
    }

    .people-info .people-info-r .people-name {
        width: 115px;
        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
    }

    .people-info .people-info-r .people-intro {
        width: 115px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 6px;
    }

    .r-limit {
        box-sizing: border-box;
        /*width: 177px;*/
        padding-left: 86px;
    }

    .r-reason {
        width: 177px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .r-reason-model {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
        text-decoration: underline;
    }

    .btn-group {
        overflow: hidden;
        margin: 39px auto 0;
        width: 216px;
    }

    .btn-group .btn-group-item {
        cursor: pointer;
        width: 99px;
        height: 32px;
        background: #000000;
        border-radius: 131px;
        line-height: 32px;
        text-align: center;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
    }

    .interview-time {
        box-sizing: border-box;
        width: 166px;
        height: 49px;
        background: #f1f1f1;
        border-radius: 131px;
        padding: 5px 37px;

        text-align: center;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
        margin: 0 auto;
    }

    .operation {
        text-align: center;
        /*padding-top: 10px;*/
    }

    .btn-op {
    }

    .btn-op-item {
        margin: 0 auto 16px;

        cursor: pointer;
        box-sizing: border-box;
        width: 99px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .ban {
        border: 1px solid #f1f1f1;
    }

    .no-op {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .speed-progress {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .speed-progress-tip {
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #707070;
        margin-top: 5px;
    }

    .start-date {
        padding-top: 10px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .invited-people-icon {
        width: 33px;
        height: 33px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        margin-right: 8px;
    }

    .invited-people-text {
        width: 44px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        display: inline-block;
        vertical-align: middle;
    }

    .icon-value {
    }

    .icon-stack {
        position: relative;
        height: 33px;
        width: 75px;
        margin: 0 auto;
    }

    .icon-value-text {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
        margin: 0 auto;
        width: 90px;
        text-align: center;
        margin-top: 12px;
    }

    .icon-stack img {
        position: absolute;
        width: 33px;
        height: 33px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .icon-stack img:first-child {
        left: 0;
    }

    .icon-stack img:nth-child(2) {
        left: 14px;
    }

    .icon-stack img:nth-child(3) {
        left: 28px;
    }

    .icon-stack img:nth-child(4) {
        left: 42px;
    }

    .price {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        text-align: center;
        padding-top: 20px;
    }
</style>
