/*
* 我参与的项目相关接口
* */
import request from "@/utils/request";

// 我参与的项目列表表
export function myParticipateList(data) {
    return request({
        url: '/myJoinProject/projectList',
        method: 'get',
        params: data,
    })
}

// 我参与的项目，根据类型统计
export function selectStatisticsForType(data) {
    return request({
        url: '/myJoinProject/selectStatisticsForType',
        method: 'get',
        params: data,
    })
}
