<template>
    <div class="UlList2-container">
        <ul>
            <li :key="index" v-for="(item,index) in list">
                <div class="list-item">
                    <!-- 时间 -->
                    <div class="date-area">
                        <div class="date-area-value">{{ item.referrerTime }}</div>
                        <!-- 关注时间 | 推荐时间 -->
                        <div class="date-area-label">
                            {{ item.dataType === '4' ? $t('myProject.focusOnTime') : $t('myProject.recommendedTime') }}
                        </div>
                    </div>
                    <!-- 推荐人 -->
                    <div class="people-area">
                        <img :src="item.contactsAvatar" alt="">
                        <div class="pa-text">
                            <div class="pat-name">{{ item.contactsName }}</div>
                            <!-- 推荐者 -->
                            <div class="pat-label" v-if="item.dataType == 1 || item.dataType == 2">{{
                                $t('myProject.recommender') }}
                            </div>
                            <!-- 接收者 -->
                            <div class="pat-label" v-if="item.dataType == 3">{{ $t('myProject.receiver') }}</div>
                            <!-- 被关注者 -->
                            <div class="pat-label" v-if="item.dataType == 4">{{ $t('myProject.follower') }}</div>
                        </div>
                        <div class="pa-btn-group" v-show="item.dataType!=2">
                            <!-- 立即购买 -->
                            <div @click="$router.push({path: '/user/index',query:{userType:2,talentId:item.contactsId,}})"
                                 class="pbg-btn">
                                {{ $t('myProject.buyNow') }}
                            </div>
                            <!-- 立即关注 -->
                            <div @click.prevent="attention($event,1,item,false)" class="pbg-btn"
                                 v-if="item.contactsFollowed==0" v-preventReClick>
                                {{ $t('myProject.immediateAttention') }}
                            </div>
                            <!-- 取消关注 -->
                            <div @click.prevent="attention($event,2,item,false)" class="pbg-btn ban"
                                 v-else
                                 v-preventReClick>{{ $t('myProject.unfollow') }}
                            </div>
                        </div>
                    </div>
                    <!-- 被推荐人 -->
                    <div class="people-area" v-show="item.dataType!=4">
                        <img :src="item.introducerAvatar" alt="">
                        <div class="pa-text">
                            <div class="pat-name">{{ item.introducerName }}</div>
                            <!-- 被推荐者 -->
                            <div class="pat-label">{{ $t('myProject.recommendedPerson') }}</div>
                        </div>
                        <div class="pa-btn-group">
                            <!-- 立即购买 -->
                            <div @click="$router.push({path: '/user/index',query:{userType:2, talentId:item.introducerId,}})"
                                 class="pbg-btn">
                                {{ $t('myProject.buyNow') }}
                            </div>
                            <!-- 立即关注 -->
                            <div @click.prevent="attention($event,1,item,true)" class="pbg-btn"
                                 v-if="item.introducerFollowed==0" v-preventReClick>
                                {{ $t('myProject.immediateAttention') }}
                            </div>
                            <!-- 取消关注 -->
                            <div @click.prevent="attention($event,2,item,true)" class="pbg-btn ban" v-else
                                 v-preventReClick>
                                {{ $t('myProject.unfollow') }}
                            </div>
                        </div>
                    </div>
                    <!-- 原因 -->
                    <span class="reason">{{ item.recommend_reason }}</span>
                </div>
            </li>
            <li></li>
            <li></li>
        </ul>
    </div>
</template>

<script>
    import {followTalent} from "@/api/memberApi";

    export default {
        name: "UlList2",
        inject: ["reload"], //注入reload方法
        data() {
            return {};
        },
        components: {},
        props: ['list'],
        methods: {
            //关注
            attention(event, type, item, isIntroducer) {
                if (event.target.disabled) {
                    // 点击太频繁了
                    this.$message.warning(this.$t('myProject.clickFrequentMsg'))
                    return
                }

                let formData = {
                    operateType: type,
                    talentId: isIntroducer ? item.introducerId : item.contactsId,
                    talentName: isIntroducer ? item.introducerName : item.contactsName,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            if (isIntroducer) {
                                item.introducerFollowed = 1
                            } else {
                                item.contactsFollowed = 1
                            }
                            // 关注成功
                            this.$message.success(this.$t('myProject.followMsg.followSuccess'))
                        } else {
                            if (isIntroducer) {
                                item.introducerFollowed = 0
                            } else {
                                item.contactsFollowed = 0
                            }
                            // 取消关注成功
                            this.$message.success(this.$t('myProject.followMsg.unfollowSuccess'))
                            // this.reload();
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .UlList2-container {
        margin-top: 50px;
    }

    li {
        margin-bottom: 50px;
    }

    li:last-child {
        margin-bottom: 0;
    }

    .list-item {
    }

    .date-area {
        display: inline-block;
        vertical-align: middle;
        width: 179px;
    }

    .date-area-value {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .date-area-label {

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 3px;
    }

    .people-area {
        display: inline-block;
        vertical-align: middle;
        width: 400px;
    }

    .people-area > img {
        display: inline-block;
        vertical-align: middle;
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        overflow: hidden;

    }

    .pa-text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
        width: 138px
    }


    .pat-name {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
    }

    .pat-label {
        margin-top: 5px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
    }

    .pa-btn-group {
        display: inline-block;
        vertical-align: middle;
    }

    .pbg-btn {
        cursor: pointer;
        width: 99px;
        height: 32px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 32px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-bottom: 8px;
    }

    .ban {
        color: #000000;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
    }

    .reason {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }
</style>
