<template>
    <div class="UlList-container">
        <ul>
            <li :key="index"
                @click=" $router.push( '/myPublishedProject?id=' + item.project_id + '&type=1&pd_type=' + item.data_type)"
                v-for="(item, index) in list">
                <h1 class="over">{{ item.initiator }}：{{ item.project_name }}</h1>
                <h2 class="over">{{ item.project_intro }}</h2>
                <table>
                    <tr>
                        <td v-if="item.freeLinkupList.length!=0">
                            <div class="icon-value">
                                <div class="icon-stack">
                                    <img :key="index" :src="obj.avatar" alt=""
                                         v-for="(obj,index) in item.freeLinkupList" v-if="index<3"/>
                                    <img alt="" src="@/assets/images/img_more@2x.png"
                                         v-if="item.freeLinkupList.length>2"/>
                                </div>
                                <span class="icon-value-text">{{ item.freeLinkupList.length + $t("myProject.personFreeCommunication")}}</span>
                            </div>
                        </td>
                        <td v-if="item.data_type == 3 && item.invitation_time != ''">
                            <div class="icon-value">
                                <div style="width: 180px;">
                                    {{$t('myProject.multiUserCommunicationTime')}}
                                </div>
                                <span style="display: inline-block; margin-top: 15px">{{ item.invitation_time }}</span>
                            </div>
                        </td>
                        <td v-if="item.data_type == 3 && item.consentList.length!=0">
                            <div class="icon-value">
                                <div class="icon-stack">
                                    <img :key="index" :src="obj.avatar" alt="" v-for="(obj,index) in item.consentList"/>
                                    <img alt="" src="@/assets/images/img_more@2x.png" v-if="item.consentList.length>2"/>
                                </div>
                                <span class="icon-value-text">{{ item.consentList.length +" "+ $t("myProject.personAgreed")}}</span>
                            </div>
                        </td>
                        <td v-if="item.viewList.length!=0">
                            <div class="icon-value">
                                <div class="icon-stack">
                                    <img :key="index" :src="obj.avatar" alt="" v-for="(obj,index) in item.viewList"
                                         v-if="index<3"/>
                                    <img alt="" src="@/assets/images/img_more@2x.png" v-if="item.viewList.length>2"/>
                                </div>
                                <span class="icon-value-text">{{ item.viewList.length + $t("myProject.toView") }}</span>
                            </div>
                        </td>
                        <td v-if="item.referralsList.length!=0">
                            <div class="icon-value">
                                <div class="icon-stack">
                                    <img :key="index" :src="obj.avatar" alt="" v-for="(obj,index) in item.referralsList"
                                         v-if="index<3"/>
                                    <img alt="" src="@/assets/images/img_more@2x.png"
                                         v-if="item.referralsList.length>2"/>
                                </div>
                                <span class="icon-value-text">{{ item.referralsList.length + $t("myProject.recommendProject") }}</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "UlList",
        data() {
            return {};
        },
        components: {},
        props: ["list"],
    };
</script>

<style scoped>
    .UlList-container {
        margin-top: 60px;
    }

    ul {
    }

    li {
        margin-bottom: 60px;
        cursor: pointer;
    }

    h1 {
        width: 1000px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 21px;
        color: #000000;
    }

    h2 {
        width: 1000px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        margin-top: 13px;
    }

    .state {
        margin-top: 20px;
        overflow: hidden;
    }

    .icon-value {
        height: 33px;
    }

    .icon-stack {
        position: relative;
        height: 100%;
        width: 75px;
        display: inline-block;
        vertical-align: middle;
    }

    .icon-value-text {
        vertical-align: middle;
        margin-left: 11px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
    }

    .icon-stack img {
        position: absolute;
        width: 33px;
        height: 33px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .icon-stack img:first-child {
        left: 0;
    }

    .icon-stack img:nth-child(2) {
        left: 14px;
    }

    .icon-stack img:nth-child(3) {
        left: 28px;
    }

    .icon-stack img:nth-child(4) {
        left: 42px;
    }

    .multi-people-time {
        width: 166px;
        height: 48px;
        background: #f1f1f1;
        border-radius: 131px;
        padding-top: 11px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 114px;
    }

    .mpt-key {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        text-align: center;
    }

    .mpt-val {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 17px;
        color: #000000;
        text-align: center;
        margin-top: 4px;
    }

    .invite-rate {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        vertical-align: middle;
        margin-right: 186px;
    }

    table {
        margin-top: 20px;
    }

    table > tr > td {
        width: 280px;
    }
</style>
