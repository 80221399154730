<template>
    <el-popover
            class="progress-bar"
            placement="right"
            trigger="hover"
            width="134"
    >
        <div class="content">
            <div :class="{'progress-item-end':index==list.length-1}" :key="index" class="progress-item"
                 v-for="(item,index) in list">
                <div class="progress-item-number">{{ index+1 }}</div>
                <p :class="{'target':item.is_checked==1}" class="progress-name">{{ item.dict_label }}</p>
            </div>
        </div>
        <el-button slot="reference">
            <slot></slot>
        </el-button>
    </el-popover>
</template>

<script>
    import {interviewProgressList} from "@/api/myProjectApi";

    export default {
        name: "ProgressBar",
        data() {
            return {
                list: [],
            };
        },
        components: {},
        props: {
            id: {
                default: ''
            },
            interviewType: {
                type: Number,
                default: 2,
            }
        },
        mounted() {
            this.getInterviewProgressList(this.id);
        },
        methods: {
            async getInterviewProgressList(interviewId) {
                let res = await interviewProgressList({
                    interviewId: interviewId,
                    interviewType: this.interviewType,
                });
                if (res.code == 200) {
                    this.list = res.data;
                }
            },
        },
    }
</script>

<style scoped>
    .progress-bar .el-button {
        border: none;
    }

    .progress-bar .el-button:hover {
        background-color: transparent !important;
    }

    .content {
        width: 100%;
        /*height: 100px;*/
        padding: 9px 10px;
    }

    .progress-item {
        height: 42px;
        border-left: 1px solid #F1F1F1;
        position: relative;
        padding-left: 16px;
    }

    .progress-item-end {
        border-left: none;

    }

    .progress-item-number {
        position: absolute;
        left: -8.5px;
        width: 17px;
        height: 17px;
        background: #000000;
        border-radius: 50%;
        text-align: center;
        line-height: 17px;

        font-size: 9px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
    }

    .progress-name {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .target {
        font-weight: bold !important;
    }

</style>
