/*
* 我的访谈相关接口
* */
import request from "@/utils/request";

// 我的访谈列表
export function myInterviewList(data) {
    return request({
        url: '/myInterview/interviewList',
        method: 'get',
        params: data,
    })
}

// 我的访谈 项目
export function myInterviewListDetails(data) {
    return request({
        url: '/myInterview/interviewDetails',
        method: 'get',
        params: data,
    })
}

// 我的访谈 vip订单
export function buyVipService(data) {
    return request({
        url: '/projectMinute/buyVipService',
        method: 'get',
        params: data,
    })
}

// 我的访谈 vip订单私密
export function inviteDetailsForPrivacy(data) {
    return request({
        url: '/myInterview/inviteDetailsForPrivacy',
        method: 'POST',
        params: data,
    })
}

// 我的访谈 访谈详情
export function interviewList(data) {
    return request({
        url: '/myInterview/initiatorDetails',
        method: 'get',
        params: data,
    })
}

// 我的访谈 公开发布
export function publicRelease(data) {
    return request({
        url: '/myInterview/projectPublic',
        method: 'POST',
        params: data,
    })
}

// 我的访谈 公开发布
export function serviceInfo(data) {
    return request({
        url: '/vipService/serviceInfo',
        method: 'GET',
        params: data,
    })
}

// 我的访谈 发起多人邀请
export function initiatePeopleInterviewForThree(data) {
    return request({
        url: '/myInterview/initiatePeopleInterviewForThree',
        method: 'POST',
        params: data,
    })
}

// 我的访谈 发起多人邀请
export function multiInviteDetail(data) {
    return request({
        url: '/myInterview/multiInviteDetail',
        method: 'get',
        params: data,
    })
}

//20、免费沟通邀约详情页面-VIP私密推荐 A(发起者发起五个时间的页面)
export function threePrivacyDetails(data) {
    return request({
        url: '/myInterview/threePrivacyDetails',
        method: 'get',
        params: data,
    })
}

//20、免费沟通邀约详情页面-VIP私密推荐 A(发起者发起五个时间的页面)
export function threeInviteeFeedback(data) {
    return request({
        url: '/myInterview/threeInviteeFeedback',
        method: 'post',
        params: data,
    })
}

//我发起的3分钟访谈-详情页-发起人取消邀约（访谈
export function setPublisherCancelInvitation(data) {
    return request({
        url: '/projectMinute/initiatorCancel',
        method: 'post',
        params: data,
    })
}

//受邀人评论(VIP私密推荐)A
export function inviteeEvaluate(data) {
    return request({
        url: '/myInterview/inviteeEvaluate',
        method: 'post',
        params: data,
    })
}

//我收到的3分钟访谈详情-受邀人 A
export function selectBuyThreeInviteById(data) {
    return request({
        url: '/myInterview/selectBuyThreeInviteById',
        method: 'post',
        params: data,
    })
}

//我收到的3分钟访谈详情-受邀人 A
export function threeBuyInviteeFeedback(data) {
    return request({
        url: '/myInterview/threeBuyInviteeFeedback',
        method: 'post',
        params: data,
    })
}

//受邀人评论(购买)A~
export function inviteeBuyEvaluate(data) {
    return request({
        url: '/myInterview/inviteeBuyEvaluate',
        method: 'post',
        params: data,
    })
}

//受邀人评论编辑提交A
export function inviteeEvaluateEdit(data) {
    return request({
        url: '/myJoinProject/inviteeEvaluateEdit',
        method: 'post',
        params: data,
    })
}


//vip私密访谈受邀人评论编辑提交A
export function inviteeEditorialComment(data) {
    return request({
        url: '/myInterview/editorialComment',
        method: 'post',
        params: data,
    })
}

//受邀人评论编辑提交A
export function publisherImmediateEvaluation(data) {
    return request({
        url: '/myInterview/publisherImmediateEvaluation',
        method: 'post',
        params: data,
    })
}

//我发起的3分钟访谈-详情页-发起人取消邀约（访谈）A
export function publisherCancelInvitation(data) {
    return request({
        url: '/myInterview/publisherCancelInvitation',
        method: 'post',
        data,
    })
}


//发起人3分钟访谈，VIP私密推荐-发起多人邀约跳转页面~
export function multiplayerInterviewForDetails(data) {
    return request({
        url: '/myInterview/multiplayerInterviewForDetails',
        method: 'get',
        params: data,
    })
}


//三分钟访谈项目详情页-多人沟通A
export function multiThreeProDetails(data) {
    return request({
        url: '/myInterview/multiThreeProDetails',
        method: 'get',
        params: data,
    })
}


//三分钟访谈受邀人邀约反馈提交-多人沟通A
export function multiThreeInviteeFeedback(data) {
    return request({
        url: '/myInterview/multiThreeInviteeFeedback',
        method: 'post',
        params: data,
    })
}

//三分钟访谈受邀人邀约取消提交-多人沟通A
export function multiThreeInviteeCancel(data) {
    return request({
        url: '/myInterview/multiThreeInviteeCancel',
        method: 'post',
        params: data,
    })
}

//三分钟访谈角标
export function selectInterviewStatistics(data) {
    return request({
        url: '/myInterview/selectInterviewStatistics',
        method: 'get',
        params: data,
    })
}


