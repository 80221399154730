/*
* 项目、访谈公共接口*/
import request from "@/utils/request";

// 发起人免费沟通、拒绝（VIP私密推荐）
export function initiatorFeedback(data) {
    return request({
        url: '/projectMinute/initiatorFeedback',
        method: 'post',
        params: data,
    })
}

//平台推荐，被推荐会员初次反馈提交（项目、3分钟访谈共用） A
export function referralFeedback(data) {
    return request({
        url: '/myJoinProject/referralFeedback',
        method: 'post',
        params: data,
    })
}

//获取访谈邀约流程进度条 A
export function interviewProgressList(data) {
    return request({
        url: '/auxiliaryData/interviewProgressList',
        method: 'get',
        params: data,
    })
}

//进入三方约谈（结束访谈操作）
export function endInterview(data) {
    return request({
        url: '/shengwang/finishInterview',
        method: 'post',
        params: data,
    })
}
