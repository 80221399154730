import { render, staticRenderFns } from "./UlList2.vue?vue&type=template&id=4451fc7c&scoped=true&"
import script from "./UlList2.vue?vue&type=script&lang=js&"
export * from "./UlList2.vue?vue&type=script&lang=js&"
import style0 from "./UlList2.vue?vue&type=style&index=0&id=4451fc7c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4451fc7c",
  null
  
)

export default component.exports