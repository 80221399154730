<template>
    <div>
        <table>
            <thead>
            <tr>
                <td>{{ $t('myProject.basicProjectInfo') }}</td>
                <td>{{ $t('myProject.reasonsForRecommendation') }}</td>
                <td>{{ $t('myProject.interviewTime') }}</td>
                <td>{{ $t('myProject.operation') }}</td>
                <td>{{ $t('myProject.speedOfProgress') }}</td>
                <td>{{ $t('myProject.startTime') }}</td>
            </tr>
            </thead>
            <tbody>
            <tr @click="path_tovip(item)" v-bind:key="index" v-for="(item, index) in list">
                <td>
                    <div class="project-title over">{{ item.project_name }}</div>
                    <div class="people-info" v-if="item.is_hidden_issuer == '1'">
                        <!--<div class="people-info">-->
                        <div class="head-icon fl">
                            <img :src="item.avatar" alt="" style="width: 100%; height: 100%"/>
                        </div>
                        <div class="people-info-r fl">
                            <div class="people-name over">{{ item.name }}</div>
                            <div class="people-intro over">
                                {{ item.company_name }}· {{ item.position }}
                            </div>
                        </div>
                    </div>
                </td>

                <td>
                    <div class="r-limit">
                        <!--<div class="r-reason">{{ item.recommend_reason }}</div>-->
                        <a class="r-reason-model" href="javascript:void(0);" v-show="item.data_type == 2">{{
                            $t('myProject.recommendedDetails') }}</a>
                    </div>
                    <div class="btn-group" v-if="item.is_hidden_issuer == '1'">
                        <!--<div class="btn-group">-->
                        <div @click="$router.push({path: '/user/index', query: {userType: 2, talentId: item.id,},})"
                             class="btn-group-item cur fl">
                            {{ $t('myProject.buyNow') }}
                        </div>
                        <div v-if="item.followed == 0">
                            <div class="btn-group-item cur fl" style="margin-left: 18px">{{
                                $t('myProject.immediateAttention') }}
                            </div>
                        </div>
                        <div v-else>
                            <div class="btn-group-item cur fl" style="margin-left: 18px">{{ $t('project.followed') }}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="interview-time" v-if="item.invitation_time">
                        {{ item.invitation_time }}
                    </div>
                    <div class="no-op" v-else>-</div>
                </td>
                <td>
                    <!--VIP私密推荐项目流程状态
                    访谈进度 1:待推荐会员反馈；2:推荐会员拒绝；3:待发起人反馈；4:发起人拒绝；5:待受邀人反馈；6:受邀人拒绝；7:受邀人同意；8:发起人取消；9:受邀人取消；10:访谈结束',
                    进度状态 1:待推荐会员反馈；2:推荐会员拒绝；3:待发起人反馈；4:发起人拒绝；5:待受邀人反馈；6:受邀人拒绝；7:受邀人同意；8:发起人取消；9:受邀人取消；10:访谈结束；11:发起人评价；12:受邀人评价',
                    免费项目流程状态
                    访谈进度 1:待发起人反馈；2:发起人拒绝；3:待受邀人反馈；4:受邀人拒绝；5:受邀人同意；6:发起人取消；7:受邀人取消；8:访谈结束；9:发起人已评论；10：受邀人已评论 [-1：待被推荐人反馈；0：被推荐人拒绝（会员推荐 - 专用）]',
                    进度状态 1:待发起人反馈；2:发起人拒绝；3:待受邀人反馈；4:受邀人拒绝；5:受邀人同意；6:发起人取消；7:受邀人取消；8:访谈结束；9:发起人已评论；10：受邀人已评论；[-1：待被推荐人反馈；0：被推荐人拒绝（会员推荐 - 专用）]',
                    三分钟访谈项目流程状态
                    访谈进度 1:待受邀人反馈; 2:受邀人拒绝; 3:受邀人同意; 4:发起者取消; 5:受邀人取消; 6:访谈结束''',
                    进度状态 1:待受邀人反馈;  2:受邀人拒绝;  3:受邀人同意;  4:发起者取消;  5:受邀人取消;  6:访谈结束； 7:发起人已评论； 8：受邀人已评论； 9：申请退款; 10：退款反馈； 11：退款成功',
                     -->
                    <div class="operation">
                        <!--我参与的项目下，全部0、我感兴趣愿意免费沟通项目1、我浏览过的项目5tab页内不展示愿意免费沟通与拒绝按钮-->
                        <div v-if="tabindex == 0 || tabindex == 1"><!--我参与的项目   0：全部 1：我感兴趣愿意免费沟通项目 5：我浏览过的项目-->
                            <!--流程节点为1/2/5/8/10且数据类型不为1的情况下不展示愿意免费沟通与拒绝按钮-->
                            <div class="btn-op" v-if="item.progress != 1 && item.progress != 2 && item.progress != 4 && item.progress != 5 && item.progress != 6
                    && item.progress != 7 && item.progress != 8 && item.progress != 10 && item.data_type == 1">
                                <div class="btn-op-item cur fl">{{ $t('myProject.willingToCommunicateForFree') }}</div>
                                <div @click="refuse" class="btn-op-item ban fr cur">{{ $t('myProject.refuse') }}</div>
                            </div>
                            <div class="btn-op"
                                 v-else-if="(item.progress == 1 || item.progress == 5) && item.data_type == 2">
                                <div class="btn-op-item cur fl">{{ $t('myProject.willingToCommunicateForFree') }}</div>
                                <div @click="refuse" class="btn-op-item ban fr cur">{{ $t('myProject.refuse') }}</div>
                            </div>
                            <div class="btn-op"
                                 v-else-if="(item.progress == -1 || item.progress == 3) && item.data_type == 4">
                                <div class="btn-op-item cur fl">{{ $t('myProject.willingToCommunicateForFree') }}</div>
                                <div @click="refuse" class="btn-op-item ban fr cur">{{ $t('myProject.refuse') }}</div>
                            </div>
                            <div class="btn-op" v-else-if="item.progress == 3 && item.data_type == 5">
                                <div class="btn-op-item cur fl">{{ $t('myProject.willingToCommunicateForFree') }}</div>
                                <div @click="refuse" class="btn-op-item ban fr cur">{{ $t('myProject.refuse') }}</div>
                            </div>
                            <div class="btn-op" v-else-if="item.progress == 5 && item.data_type == 5">
                                <div class="btn-op-item cur " style="margin: 0 auto;" v-if="item.isFanTan==1">
                                    {{ $t('myProject.interview') }}
                                </div>
                                <div class="btn-op-item ban  cur" style="margin: 0 auto;" v-else>{{
                                    $t('myProject.cancelReservation') }}
                                </div>
                            </div>
                            <!--<div class="btn-op" v-else-if="(item.progress == 5 && item.data_type != 2)||(item.progress == 7 && item.data_type == 2)||(item.progress == 6 && item.data_type == 3)">-->
                            <!--  <div class="btn-op-item cur fl">{{ $t('myProject.interview') }}</div>-->
                            <!--</div>-->

                            <div class="no-op" v-else>-</div>
                        </div>
                        <div v-if="tabindex == 2"><!--我参与的项目   2：VIP私密推荐-->
                            <div class="btn-op" v-if="item.progress == 1 || item.progress == 5">
                                <div class="btn-op-item cur fl">{{ $t('myProject.willingToCommunicateForFree') }}</div>
                                <div @click="refuse" class="btn-op-item ban fr cur">{{ $t('myProject.refuse') }}</div>
                            </div>

                            <div class="no-op" v-else>-</div>
                        </div>
                        <div v-if="tabindex == 4"><!--我参与的项目    其他会员推荐给我项目-->
                            <div class="btn-op" v-if="item.progress != 0 && item.progress != 1 && item.progress != 2 && item.progress != 4
              && item.progress != 5 && item.progress != 7 && item.progress != 8 && item.data_type == 4">
                                <div class="btn-op-item cur fl">{{ $t('myProject.willingToCommunicateForFree') }}</div>
                                <div @click="refuse" class="btn-op-item ban fr cur">{{ $t('myProject.refuse') }}</div>
                            </div>

                            <div class="no-op" v-else>-</div>
                        </div>
                        <div v-if="tabindex == 5"><!--我参与的项目    我浏览过的项目-->
                            <div class="btn-op"
                                 v-if="item.progress != 1 && item.progress != 2 && item.progress != 4 && item.progress != 5 && item.progress != 6 && item.progress != 7 && item.progress != 8">
                                <div class="btn-op-item cur fl">{{ $t('myProject.willingToCommunicateForFree') }}</div>
                                <div @click="refuse" class="btn-op-item ban fr cur">{{ $t('myProject.refuse') }}</div>
                            </div>

                            <div class="no-op" v-else>-</div>
                        </div>
                        <div v-if="tabindex == 6"><!--我参与的项目    发起多人沟通-->
                            <div class="btn-op" v-if="item.progress == 1">
                                <div class="btn-op-item cur fl">{{ $t('myProject.willingToCommunicateForFree') }}</div>
                                <div @click="refuse" class="btn-op-item ban fr cur">{{ $t('myProject.refuse') }}</div>
                            </div>

                            <div class="no-op" v-else>-</div>
                        </div>
                    </div>
                </td>
                <td>
                    <h1 class="speed-progress">
                        {{ typeNameList[parseInt(item.data_type) - 1] }}
                    </h1>
                    <h2 class="speed-progress-tip">{{ item.progress_name }}</h2>
                </td>
                <td>
                    <div class="start-date">{{ item.create_time }}</div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import {myParticipateList} from "@/api/myProjectApi/participateApi";

    export default {
        name: "TBodyList",
        data() {
            return {
                typeNameList: [
                    // 我感兴趣的项目
                    this.$t('myProject.interestedProject'),
                    // VIP 私密推荐
                    this.$t('myProject.vipPrivateRecommendation'),
                    // 我推荐的项目
                    this.$t('myProject.recommendItem'),
                    // 推荐给我的项目
                    this.$t('myProject.recommendedProjectToMe'),
                    // 我浏览的项目
                    this.$t('myProject.projectsIBrowsed'),
                    // 多人沟通
                    this.$t('myProject.multiPersonCommunication')
                ],
                // 1：我感兴趣；2：VIP私密推荐；3：我推荐的；4：推荐给我的；5：我浏览的；6:多人沟通
            };
        },
        components: {},
        props: ["list", "tabindex"],
        mounted() {
        },
        methods: {
            // 跳转vip详情页面
            path_tovip(key) {
                if (key.data_type == "3") {
                    this.$router.push({
                        path: "/meToOtherProject/" + key.id,
                        query: {
                            id: key.id,
                            project_Id: key.join_id,
                            pd_num: key.data_type,
                            interviewId: key.interview_id
                        },
                    });
                } else {
                    this.$router.push({
                        path: "/vipPrivateRecommendation/" + key.id,
                        query: {
                            id: key.id,
                            project_Id: key.project_Id,
                            joinId: key.join_id,
                            pd_num: key.data_type,
                            interviewId: key.interview_id
                        },
                    });
                }
            },
            get_data(key) {
                this.list = key;
            },
            refuse() {
                this.$emit("isRefuse", true);
            },
        },
    };
</script>

<style scoped>
    table {
        width: 100%;
        margin-top: 26px;
        /*table-layout: fixed*/
        margin-bottom: 30px;
    }

    table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    table > thead > tr > td {
        vertical-align: middle;
        text-align: center;
    }

    table > thead > tr > td:first-child {
        width: 179px;
    }

    table > thead > tr > td:nth-child(2) {
        width: 310px;
    }

    table > thead > tr > td:nth-child(3) {
        width: 166px;
    }

    table > thead > tr > td:nth-child(4) {
        width: 256px;
    }

    tbody > tr {
        height: 174px !important;
        box-sizing: border-box;
    }

    tbody > tr > td {
        padding-top: 52px;
        height: 174px !important;
        box-sizing: border-box;
        vertical-align: top;
    }

    .project-title {
        width: 177px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 21px;
        color: #000000;
    }

    .project-intro {
        width: 179px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        margin-top: 13px;
    }

    .people-info {
        width: 177px;
        height: 49px;
        margin-top: 20px;
        overflow: hidden;
    }

    .people-info .head-icon {
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        overflow: hidden;
    }

    .people-info .people-info-r {
        margin-left: 12px;
        padding-top: 4px;
        height: 100%;
    }

    .people-info .people-info-r .people-name {
        width: 110px;
        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
    }

    .people-info .people-info-r .people-intro {
        width: 110px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 6px;
    }

    .r-limit {
        box-sizing: border-box;
        width: fit-content;
        margin: 0 auto;
    }

    .r-reason {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .r-reason-model {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
        text-decoration: underline;
    }

    .btn-group {
        overflow: hidden;
        margin: 39px auto 0;
        width: 216px;
    }

    .btn-group .btn-group-item {
        cursor: pointer;
        width: 99px;
        height: 32px;
        background: #000000;
        border-radius: 131px;
        line-height: 32px;
        text-align: center;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
    }

    .ban {
        color: #000000;
        background: #ffffff;
        border: 1px solid #f1f1f1;
    }

    .interview-time {
        box-sizing: border-box;
        width: 166px;
        height: 49px;
        background: #f1f1f1;
        border-radius: 131px;
        padding: 5px 37px;

        text-align: center;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
    }

    .operation {
        text-align: center;
        /*padding-top: 10px;*/
    }

    .btn-op {
        width: 216px;
        height: 32px;
        overflow: hidden;
        margin: 0 auto;
    }

    .btn-op-item {
        cursor: pointer;
        box-sizing: border-box;
        width: 99px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .ban {
        border: 1px solid #f1f1f1;
    }

    .no-op {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        text-align: center;
    }

    .speed-progress {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .speed-progress-tip {
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #707070;
        margin-top: 5px;
    }

    .start-date {
        padding-top: 10px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }
</style>
